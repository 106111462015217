import Elysia from "elysia";
import { redisClient } from "../cache/redisClient";
import { config } from "../config";
import { FileStorage } from "../services/fileStorage";

export const fileStorageController = new Elysia().get(
  "resources/:name",
  async ({ redirect, params, set, query }) => {
    const url = await FileStorage.getPresignedUrl(params.name);
    const mediaUrl = config.network.mediaUrl;
    set.headers["cache-control"] = "public, max-age=180, immutable";

    if (
      mediaUrl.includes("localhost") ||
      mediaUrl.includes("127.0.0.1") ||
      mediaUrl.includes("0.0.0.0")
    ) {
      return redirect(url, 302);
    }

    const urlObject = new URL(url);
    const redirectUrl = `${mediaUrl}/${config.minio.bucketName}/${params.name}${urlObject.search}`;

    if (query["cache-bust"] === "true") {
      const cachedBuffer = await redisClient.get(params.name);
      if (cachedBuffer) {
        const file = new File([cachedBuffer], params.name);
        set.headers["Content-Type"] = file.type;
        return new Response(file);
      }

      const response = await fetch(redirectUrl);
      const blob = await response.blob();
      const file = new File([blob], params.name, { type: blob.type });

      const arrBuf = await file.arrayBuffer();
      const buffer = Buffer.from(arrBuf);
      await redisClient.set(params.name, buffer, "EX", 18000);

      set.headers["Content-Type"] = blob.type;
      return new Response(file);
    }

    const handler = redirect(redirectUrl, 302);
    return handler;
  }
);
