import { Html } from "@elysiajs/html";
import { config } from "../config";

export function Footer(): JSX.Element {
  return (
    <footer class="overflow-hidden flex mt-auto bg-gradient-to-b md:bg-gradient-to-r from-[#4C5424] to-[#333916] text-white">
      <div class="relative max-w-[1440px] mx-auto w-full flex flex-col lg:flex-row md:gap-8 lg:gap-0 md:items-center lg:items-baseline min-h-[302.75px] px-6 py-12 md:py-16 md:px-12">
        <img
          src="/public/images/icon-white.avif"
          alt=""
          width="376px"
          height="376px"
          class="hidden lg:!block absolute top-[102px] -right-[105px] opacity-25"
          loading="lazy"
        />
        <div class="self-center md:mx-auto lg:mx-0 lg:mr-5 xl:mr-[110px] mb-6 md:mb-0 shrink-0">
          <img
            src="/public/images/logo-white.avif"
            alt="logo"
            width="200px"
            height="96px"
            class="w-[200px] sm:w-[311px]"
            loading="lazy"
          />
        </div>
        <div class="flex flex-col md:flex-row gap-y-3 gap-x-[30px] text-center md:text-left z-10">
          <div class="flex flex-col md:min-w-[264px] xl:min-w-[344px]">
            <div class="text-sm sm:text-xl font-medium mb-3 md:mb-6">
              Tentang Kami
            </div>
            <ul class="grid grid-cols-2 gap-y-1.5 md:gap-y-3 gap-x-6 text-[13px] sm:text-sm md:text-base">
              <li>
                <a href="/">Jus Kode</a>
              </li>
              <li>
                <a href="/investments">Investasi</a>
              </li>
              <li>
                <a href="/menus">Menu</a>
              </li>
              <li>
                <a href="/careers">Karir</a>
              </li>
              <li>
                <a href="/branches">Kedai Kode</a>
              </li>
              <li>
                <a href="/contact-us">Hubungi Kami</a>
              </li>
            </ul>
          </div>
          <div class="max-w-[222px] mx-auto md:mx-0">
            <div class="text-sm sm:text-xl font-medium mb-3 md:mb-6">
              Alamat Kantor
            </div>
            <p class="text-[13px] sm:text-sm md:text-base">
              Jl. Newton Bar. III No.58
              <br /> Klp. Dua, Tangerang, Banten.
            </p>
          </div>
          <div>
            <div class="text-sm sm:text-xl font-medium mb-3 md:mb-6">
              Bantuan
            </div>
            <div class="flex items-center gap-4 justify-center md:justify-start">
              <a
                href={config.user.instagram}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-[#474D2D]"
              >
                <img
                  src="/public/images/icons/instagram.svg"
                  alt="Instagram"
                  width="18px"
                  height="18px"
                />
              </a>
              <a
                href={config.user.whatsappUrl}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-[#474D2D]"
              >
                <img
                  src="/public/images/icons/whatsapp.svg"
                  alt="Whatsapp"
                  width="18px"
                  height="18px"
                />
              </a>
              <a
                href={config.user.feedbackForm}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-primary"
              >
                <img
                  src="/public/images/icons/feedback.svg"
                  alt="Feedback"
                  width="16px"
                  height="16px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
