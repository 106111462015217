import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";

interface ButtonLinkProps extends JSX.HtmlAnchorTag {
  customClass?: string;
  size?: "sm" | "md" | "lg";
  download?: string;
}

const SIZES = {
  sm: "font-medium text-sm py-2.5 px-5",
  md: "font-medium text-base py-3 px-6",
  lg: "font-semibold text-xl py-[14px] px-8",
};

export function ButtonLink({
  href,
  target,
  download,
  children: safeChildren,
  size = "lg",
  customClass = "",
}: PropsWithChildren<ButtonLinkProps>): JSX.Element {
  return (
    <a
      href={href}
      class={cn(
        "bg-primary rounded-[20px] text-white w-fit text-center",
        SIZES[size],
        customClass
      )}
      target={target}
      download={download}
    >
      {safeChildren}
    </a>
  );
}
