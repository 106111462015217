import { Html } from "@elysiajs/html";
import { config } from "../config";
import { ButtonLink } from "./buttonLink";

interface NavbarProps {
  activeRoute: string;
}

const NAV_ITEMS = [
  {
    title: "Menu",
    route: "/menus",
  },
  {
    title: "Kedai Kode",
    route: "/branches",
  },
  {
    title: "Investasi",
    route: "/investments",
  },
  {
    title: "Karir",
    route: "/careers",
  },
  {
    title: "Hubungi Kami",
    route: "/contact-us",
  },
];

const NavList = ({ activeRoute }: NavbarProps): JSX.Element => {
  return (
    <>
      {NAV_ITEMS.map((item) => (
        <li>
          <a href={item.route} class="!transition-none">
            <span
              class={`text-base text-foreground ${
                activeRoute === item.route ? "font-bold text-primary" : ""
              }`}
              safe
            >
              {item.title}
            </span>
          </a>
        </li>
      ))}
    </>
  );
};

export function Navbar({ activeRoute }: NavbarProps): JSX.Element {
  return (
    <header
      class="bg-white mx-auto w-full px-[20px] md:px-[72px] xl:px-[135px] py-[16px] lg:py-3"
      uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
      uk-navbar
    >
      <div class="uk-navbar-left">
        <a href="/" class="uk-navbar-item uk-logo">
          <img
            src="/public/images/logo.avif"
            alt="Jus Kode Logo"
            width="88px"
            height="44px"
            class="w-[88px] h-[44px] md:w-[144px] md:h-[66px]"
          />
        </a>
      </div>
      <nav class="hidden lg:!flex uk-navbar-center">
        <ul class="uk-navbar-nav !gap-10">
          <NavList activeRoute={activeRoute} />
        </ul>
      </nav>
      <div class="hidden lg:!flex ml-auto w-[151.55px]">
        <div class="uk-navbar-item">
          <ButtonLink
            href="/public/documents/proposal.pdf"
            customClass="font-medium text-sm bg-primary text-white py-2.5 px-5 rounded-[20px]"
            download="proposal.pdf"
          >
            Lihat Proposal
          </ButtonLink>
        </div>
      </div>
      <div class="lg:!hidden uk-navbar-right w-[56px]">
        <a
          class="uk-navbar-toggle-animate uk-navbar-toggle !px-[18px]"
          uk-navbar-toggle-icon
          // @ts-ignore
          width={20}
          height={20}
          href="#"
        ></a>
        <div
          class="uk-dropbar uk-drop uk-dropbar-top pt-[24px] pb-8"
          uk-drop="stretch: x; animation: slide-top; animate-out: true"
        >
          <ul class="uk-nav !gap-5 flex flex-col items-center">
            <NavList activeRoute={activeRoute} />
            <li class="w-[95%]">
              <ButtonLink
                href="/public/documents/proposal.pdf"
                size="md"
                customClass="rounded-[4px] w-full flex justify-center"
                download="proposal.pdf"
              >
                Lihat Proposal
              </ButtonLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
