import { InternalServerError, NotFoundError } from "elysia";
import { config } from "../config";
import minioClient from "../lib/minio";

export const FileStorage = {
  getPresignedUrl: async (name: string): Promise<string> => {
    const decodedName = decodeURIComponent(name);
    if (!(await minioClient.isResourceExist(decodedName))) {
      throw new NotFoundError("Resource not found");
    }

    try {
      return minioClient.presignedGetObject(
        decodedName,
        config.minio.presignedUrlDuration
      );
    } catch (_: unknown) {
      throw new InternalServerError("Unable to get presigned URL");
    }
  },
};
