import { Html } from "@elysiajs/html";
import { Banners, MediaProps } from "../components/banner";
import { ButtonLink } from "../components/buttonLink";
import { Footer } from "../components/footer";
import { Image, ImageProps } from "../components/image";
import { Navbar } from "../components/navbar";
import { Progress } from "../components/progress";
import { Section } from "../components/section";
import { formatToRupiah } from "../utils/string";
import { calculatePercentage } from "../utils/calculations";
import BackdropEllipse from "../components/backdrop";
import EmptyState from "../components/emptyState";
import { config } from "../config";
import { cn } from "../utils/classname";

export type InvestmentCard = {
  branchName: string;
  totalInvestment: number;
  totalInvestmentCollected: number;
  location: string;
  googleMapsUrl?: string;
  description: string;
  images: { type: "image"; image: ImageProps }[];
};

export function Invest({
  investments,
  media,
}: {
  investments: InvestmentCard[];
  media: MediaProps[];
}): JSX.Element {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0 backdrop-blur-[1000px]" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/investments" />
      </div>

      <main
        class={cn("flex-1 flex flex-col", {
          "min-h-[500px] lg:min-h-[800px]":
            !!investments.length && !media.length,
          "min-h-[700px] lg:min-h-[1000px]":
            !!investments.length && !!media.length,
        })}
      >
        {media.length > 0 && (
          <section class="min-h-[200px]">
            <Banners media={media} />
          </section>
        )}
        <Section customClass="!py-10 md:!pt-[76px] !mt-0 flex flex-col gap-6">
          {investments.length > 0 ? (
            <>
              {investments.map((investment) => (
                <div class="animate-delay-200 animate-in-view-fade shadow-8px py-8 md:py-12 px-6 flex flex-col items-center md:items-start gap-1.5 md:gap-6 rounded-[20px] bg-white">
                  <div
                    class="uk-visible-toggle w-full"
                    tabindex="-1"
                    uk-slideshow="autoplay: true; animation: push;"
                  >
                    <div class="uk-slideshow-items rounded-[10px] !max-h-[200px] md:!max-h-[580px]">
                      {investment.images.map((m) => (
                        <div>
                          <Image
                            url={m.image.url}
                            dataUrl={m.image.dataUrl}
                            width={100000}
                            height={580}
                            alt={m.image.alt}
                            uk-cover
                            parentClass="!max-h-[200px] md:!max-h-[580px]"
                            customClass="!object-contain md:!object-cover rounded-[10px]"
                            loading="eager"
                          />
                        </div>
                      ))}
                    </div>
                    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center primary !mt-1.5 md:!mt-6"></ul>
                  </div>

                  <h3
                    class="text-dark-shade font-medium mb-2.5 md:font-bold md:text-[28px] md:leading-9 md:mb-0"
                    safe
                  >
                    {investment.branchName}
                  </h3>
                  <div class="flex flex-col gap-3 items-center w-full md:items-start">
                    <span
                      class="text-primary-foreground text-xl md:text-[64px] md:leading-[76px] font-semibold"
                      safe
                    >
                      {formatToRupiah(investment.totalInvestment)}
                    </span>
                    <Progress
                      value={calculatePercentage(
                        investment.totalInvestmentCollected,
                        investment.totalInvestment
                      )}
                    />
                    <p class="text-dark-grey text-sm md:text-base">
                      {
                        formatToRupiah(
                          investment.totalInvestmentCollected
                        ) as "safe"
                      }{" "}
                      / {formatToRupiah(investment.totalInvestment) as "safe"}
                    </p>
                  </div>
                  <div class="flex items-center pl-[6.25px] gap-[14.25px] mt-1 md:mt-0">
                    <img
                      src="/public/images/icons/map-pin-primary.svg"
                      alt="Alamat"
                      width="18px"
                      height="25px"
                      class="hidden md:!block"
                    />
                    <p
                      class="text-sm text-[#676767] text-center md:text-left"
                      safe
                    >
                      {investment.location}
                    </p>
                  </div>
                  <ButtonLink
                    href={investment.googleMapsUrl}
                    size="sm"
                    customClass="bg-transparent text-primary p-0"
                    target="_blank"
                  >
                    Lihat Lokasi
                  </ButtonLink>
                  <div class="hidden md:!block text-[#525252] use-default">
                    {investment.description as "safe"}
                  </div>
                  <ButtonLink
                    href={config.user.whatsappUrl}
                    size="md"
                    customClass="mt-2.5 md:mt-0 text-sm"
                  >
                    Investasi Sekarang
                  </ButtonLink>
                </div>
              ))}
            </>
          ) : (
            <EmptyState
              title="Peluang Investasi Masih Kosong"
              message="Saat ini belum ada pilihan investasi yang tersedia. Silakan kembali lagi untuk peluang terbaru."
              icon={
                <img
                  src="/public/images/icons/empty-file.svg"
                  alt=""
                  width="136"
                  height="121"
                />
              }
            />
          )}
        </Section>
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2370px] -left-[33px]" />
      <BackdropEllipse customClass="hidden md:block top-[2265px] -right-[148px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
      <BackdropEllipse customClass="hidden md:block -top-[280px] -left-[317px]" />
    </div>
  );
}
