import { S3Client } from "bun";
import { config } from "../../config";

const s3Client = new S3Client({
  accessKeyId: config.minio.accessKey,
  secretAccessKey: config.minio.secretKey,
  bucket: config.minio.bucketName,
  endpoint: config.minio.endpoint,
  region: config.minio.region,
});

const presignedGetObject = (objectName: string, expiry?: number): string => {
  return s3Client.presign(objectName, {
    expiresIn: expiry || config.minio.presignedUrlDuration,
  });
};

const isResourceExist = async (objectName: string): Promise<boolean> => {
  return s3Client
    .exists(objectName)
    .then(() => true)
    .catch(() => false);
};

export default {
  presignedGetObject,
  isResourceExist,
};
